// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-contact-success-js": () => import("./../src/templates/contact-success.js" /* webpackChunkName: "component---src-templates-contact-success-js" */),
  "component---src-templates-review-js": () => import("./../src/templates/review.js" /* webpackChunkName: "component---src-templates-review-js" */)
}

